@import url(https://rsms.me/inter/inter.css);


html,
body {
  margin: 0;
  padding: 0;
  border-spacing: 0;
}

.universaldatatool {
  font-family: "Inter";
}

